export default {
    SET_PROJECT(state, payload) {
        state.project = payload;

        const found = state.projects.findIndex(({ id }) => id === payload.id);

        if (found >= 0) {
            state.projects.splice(found, 1, payload);
        }
    },

    CLEAR_PROJECT(state) {
        state.project = {};
    },

    SET_PROJECT_COMMENTS(state, comments) {
        state.comments = comments;
    },

    SET_PROJECTS(state, payload) {
        state.projects = payload;
    },
};
