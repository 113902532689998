<template>
    <main id="main-content">
        <nav-header v-if="authorizedRoute" />
        <div v-if="loading" class="loading-spinner centered white">
            <ds-spinner />
        </div>

        <router-view v-else />
    </main>
</template>

<script>
import NavHeader from '@/components/NavHeader';
import { mapState, mapGetters } from 'vuex';

export default {
    components: {
        NavHeader,
    },

    data() {
        return {
            loading: false,
        };
    },

    mounted() {
        this.load();
    },

    watch: {
        // eslint-disable-next-line
        ['user.uid'](value) {
            if (value) this.load();
        },
    },

    computed: {
        ...mapState({
            user: ({ auth }) => auth.user,
            guests: ({ users }) => users.guests,
        }),

        ...mapGetters({
            isGuest: 'users/isGuest',
        }),

        authorizedRoute() {
            return !['login', 'logout', 'forbidden'].includes(this.$route.name);
        },
    },

    methods: {
        async load() {
            this.loading = true;

            try {
                await Promise.all([
                    this.$store.dispatch('users/LOAD_USERS'),
                    this.$store.dispatch('projects/LOAD_PROJECTS'),
                    this.$store.dispatch('hackathons/LOAD_HACKATHONS'),
                    this.$store.dispatch('hackathons/LOAD_HACKATHON_DEMO'),
                    this.$store.dispatch('hackathons/LOAD_HACKATHON_COMMENTS'),
                    this.$store.dispatch('tags/LOAD_TAGS'),
                ]);

                this.loading = false;
            } catch (error) {
                this.loading = false;

                if (this.$route.name !== 'login') this.$router.replace({ name: 'login' });
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss">
body {
    background-color: $color-gray-900;
}

.logo-keap path {
    fill: $color-gray-050 !important;
}
</style>
