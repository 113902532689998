<template>
    <header>
        <aside>
            <router-link :to="{ name: 'home' }">
                <ds-logo />
            </router-link>

            <h2 class="page-title">
                <portal-target name="title" />
            </h2>
        </aside>

        <nav v-if="user.uid">
            <DemoWidget v-if="demo.projectId" />

            <portal-target name="nav-actions" multiple />

            <DsFilledButton
                v-if="showVoteButton"
                class="vote-button"
                @click="$router.push({ name: 'hackathon.vote', params: { id: activeHackathon.id } })"
            >
                Vote now!
            </DsFilledButton>

            <DsIconButton
                v-if="showSearchButton"
                name="search"
                style="--icon-color: white"
                class="search-button"
                aria-label="Search"
                @click="$router.push({ name: 'search' })"
            />

            <DsDropdown style="--dropdown-height: 400px">
                <template #default>
                    <DsAvatar :email="user.email" />
                </template>

                <template #menu>
                    <!-- <p>
                        Welcome back,
                        <router-link :to="{ name: 'user', params: { id: user.uid } }">
                            {{ user.name }}!
                        </router-link>
                    </p> -->

                    <ul class="dropdown-menu-list">
                        <li
                            v-if="isAdmin"
                            class="dropdown-menu-item"
                            @click="$router.push({ name: 'admin' })"
                        >
                            <DsStatus emphasis="high">
                                Admin
                            </DsStatus>

                            Dashboard
                        </li>

                        <li
                            v-if="activeHackathon"
                            class="dropdown-menu-item"
                            @click="$router.push({ name: 'projects.new' })"
                        >
                            Create a project
                        </li>

                        <li
                            class="dropdown-menu-item"
                            @click="$router.push({ name: 'ideas.new' })"
                        >
                            Submit idea
                        </li>

                        <li
                            class="dropdown-menu-item"
                            @click="$router.push({ name: 'hall.of.fame' })"
                        >
                            Hall of fame
                        </li>

                        <li
                            class="dropdown-menu-item"
                            @click="$router.push({ name: 'ideas' })"
                        >
                            Browse Ideas
                        </li>

                        <li
                            class="dropdown-menu-item"
                            @click="$router.push({ name: 'user', params: { id: user.uid } })"
                        >
                            Profile
                        </li>

                        <li class="dropdown-menu-item destructive" @click="logout">
                            Log out
                        </li>
                    </ul>
                </template>
            </DsDropdown>
        </nav>
    </header>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import DemoWidget from '@/components/DemoWidget';
import moment from 'moment';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export default {
    components: {
        DemoWidget,
    },

    computed: {
        ...mapState({
            user: ({ auth }) => auth.user,
            isAdmin: ({ auth }) => auth.user.isAdmin,
            project: ({ projects }) => projects.project,
            hackathon: ({ hackathons }) => hackathons.hackathon,
            demo: ({ hackathons }) => hackathons.demo,
            hackathons: ({ hackathons }) => hackathons.hackathons,
        }),

        ...mapGetters({
            activeHackathon: 'hackathons/getActiveHackathon',
            getProject: 'projects/getProject',
        }),

        showSearchButton() {
            return this.$route.name !== 'search';
        },

        showVoteButton() {
            if (this.$route.name === 'hackathon.vote' || !this.activeHackathon) return false;

            const { startDate, endDate } = this.activeHackathon;

            return moment().isBetween(moment(startDate), moment(endDate));
        },
    },

    methods: {
        logout() {
            firebase.auth().signOut().then(() => {
                this.$store.commit('auth/SET_USER', { email: '' });
                this.$store.commit('auth/SET_GOOGLE_TOKEN', '');
                this.$router.push({ name: 'login' });
            }).catch(() => {});
        },
    },

};
</script>

<style lang="scss" scoped>
    header {
        z-index: 2;
        padding: $spacing-200 $spacing-200 0;
        align-items: center;
        display: flex;
        justify-content: space-between;

        --logo-width: #{px-to-rem(87)};
        height: px-to-rem(72);
    }

    aside {
        display: grid;
        grid-template-columns: px-to-rem(87) auto;
        align-items: center;
        gap: $spacing-200;
        padding: 0 $spacing-200;
        margin-left: $spacing-100;
        justify-content: center;
        align-items: center;
    }

    nav {
        display: flex;
        align-items: center;
    }

    h2 {
        color: $color-white;
        font-size: $font-size-lg;
        font-weight: bold;
    }

    .page-title {
        color: $color-yellow-050;

        @media($extra-small) {
            display: none;
        }
    }

    .status-container {
        margin-right: $spacing-100;
    }

    .search-button {
        margin: 0 $spacing-200;
    }

    .vote-button {
        margin-left: $spacing-200;
    }
</style>
