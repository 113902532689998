import Vue from 'vue';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import App from '@/App.vue';
import VueParticles from 'vue-particles';
import router from '@/router/router';
import store from '@/store';
import { i18n } from '@/shared/i18n';
import PortalVue from 'portal-vue';
import DesignSystem from '@infusionsoft/design-system';
import components from '@infusionsoft/design-system/src/components';
import '@/styles/main.scss';
import '@/styles/fonts.scss';

const icons = require.context('@infusionsoft/design-system/src/assets/icons', false, /.*\.svg$/).keys()
    .map((fileName) => fileName.replace('./', '').replace('.svg', ''));
const illustrations = require.context('@infusionsoft/design-system/src/assets/images/illustrations', false, /.*\.svg$/).keys()
    .map((fileName) => fileName.replace('./', '').replace('.svg', ''));


Vue.config.productionTip = false;

Vue.use(PortalVue);
Vue.use(VueParticles);
Vue.use(DesignSystem, { components, icons, illustrations });

if (process.env.NODE_ENV === 'development') {
    Vue.config.devtools = true;
    Vue.config.debug = true;
    Vue.config.productionTip = false;
}

Vue.prototype.$i18nInstance = i18n;
Vue.prototype.$bus = new Vue();

const config = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    databaseURL: `https://${process.env.VUE_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
    authDomain: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
    storageBucket: 'hackathon-5c63b.appspot.com',
};

firebase.initializeApp(config);

let app = '';

firebase.auth().getRedirectResult().then((result) => {
    const hasGoogleToken = store?.state?.auth?.googleToken;

    if (result.credential && !hasGoogleToken) {
        store.commit('auth/SET_GOOGLE_TOKEN', result.credential.idToken);
        store.commit('auth/SET_UNAUTHENTICATED_COUNT', 0);

        const db = firebase.firestore();

        const { creationTime, lastSignInTime } = result.user.metadata;
        const { email, name, picture } = result.additionalUserInfo.profile;

        const user = {
            uid: result.user.uid,
            email,
            name,
            photoUrl: picture,
            creationTime,
            lastSignInTime,
        };

        db.collection('users').doc(result.user.uid).get()
            .then((doc) => {
                if (doc.data()) {
                    store.commit('auth/SET_USER', {
                        isAdmin: doc.data().isAdmin,
                        ...user,
                    });
                } else {
                    db.collection('users').doc(result.user.uid).set(user)
                        .then((newDoc) => {
                            store.commit('auth/SET_USER', {
                                isAdmin: newDoc.data().isAdmin,
                                ...user,
                            });
                        })
                        .catch((e) => {
                            // eslint-disable-next-line no-console
                            console.log('error', e);
                        });
                }
            })
            .catch((e) => {
                // eslint-disable-next-line no-console
                console.log('error', e);
            });
    }
}).catch((error) => {
    // eslint-disable-next-line no-console
    console.log('Error redirecting', error);
});

firebase.auth().onAuthStateChanged(() => {
    if (!app) {
    /* eslint-disable no-new */
        app = new Vue({
            router,
            store,
            i18n,
            render: (h) => h(App),
        }).$mount('#app');
    }
});
